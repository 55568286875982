import { Paper, Box } from "@mui/material";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";

import { urlRoutes } from "./constants";
import { useUser } from "../../context/AuthContext";
import { isEmailRegex } from "../../shared/components/RegisterComponents/constants";
import { isWeb } from "../../utils/environments";
import LSButton from "../components/buttons/LSButton";
import Card, { cardVariant } from "../components/cards/Card";
import LSInput from "../components/formInputs/LSInput";
import PageLayout from "../components/layouts/PageLayout";
import LSText, { textVariant } from "../components/text/LSText";
import { mockNavigationWeb } from "../utils/mockNavigationWeb";

const welcomeText = "Welcome to LinkStep";
const loginTest = "Please login to get started";

const emptyLogin = {
  email: "",
  password: "",
};

const loginError = {
  email: false,
  password: false,
};

export default function Login() {
  const [user, setUser] = useState(emptyLogin);
  const [errorObj, setErrorObj] = useState(loginError);
  const [loginErrors, setLoginErrors] = useState("");
  const { login } = useUser();
  const { useNavigation } = isWeb
    ? mockNavigationWeb
    : // eslint-disable-next-line global-require
      require("@react-navigation/native");

  const navigation = useNavigation();

  const validateSignupData = () => {
    let errors = false;
    if (!isEmailRegex.test(user.email)) {
      setErrorObj((prevState) => ({
        ...prevState,
        email: true,
      }));
      errors = true;
    } else {
      setErrorObj((prevState) => ({
        ...prevState,
        email: false,
      }));
    }
    if (user.password.length < 8) {
      setErrorObj((prevState) => ({
        ...prevState,
        password: true,
      }));
      errors = true;
    } else {
      setErrorObj((prevState) => ({
        ...prevState,
        password: false,
      }));
    }
    return errors;
  };

  const handleLogin = async () => {
    const errors = validateSignupData();
    if (errors) return;

    const response = await login(user);
    if (response === "Wrong Email or Password" || response === "error") {
      setLoginErrors(response);
      return;
    }
    setLoginErrors("");

    if (isWeb) {
      window.location.href = urlRoutes.home;
    } else {
      navigation.navigate("home");
    }
  };

  return (
    <PageLayout center>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          elevation={2}
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            width: "330px",
          }}
        >
          <View style={{ marginBottom: 16 }}>
            <LSText text={welcomeText} variant={textVariant.h2} />
            <LSText text={loginTest} variant={textVariant.h4} />
            <LSText text={loginErrors} variant={textVariant.errorCenter} />
          </View>
          <View style={style.inputContainers}>
            <LSInput
              placeholder="Email"
              name="email"
              object={user}
              errorObj={errorObj}
              setter={setUser}
              customStyles={{ width: 280 }}
            />
          </View>
          <View style={style.inputContainers}>
            <LSInput
              placeholder="Password"
              name="password"
              object={user}
              errorObj={errorObj}
              setter={setUser}
              password
              customStyles={{ width: 280 }}
            />
          </View>
          <View style={style.buttonContainers}>
            <LSButton
              text="Login"
              onPress={handleLogin}
              customStyles={{ width: "100%" }}
            />
          </View>
        </Paper>
      </Box>
    </PageLayout>
  );
}

const style = StyleSheet.create({
  inputContainers: {
    width: 300,
    flexDirection: "row",
    paddingLeft: 12,
  },
  buttonContainers: {
    width: 300,
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 8,
    flexDirection: "row",
  },
});
