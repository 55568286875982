import { AntDesign, FontAwesome } from "@expo/vector-icons";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import {
  Modal,
  Pressable,
  ScrollView,
  StyleSheet,
  Switch,
  View,
  useWindowDimensions,
} from "react-native";
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuid } from "uuid";

import LSModal from "./LSModal";
import {
  dataListOptions,
  emptyAnswer,
  emptyQuestion,
  emptyQuestionShort,
  multipleChoiceEmptyQuestion,
  multipleChoiceEmptyQuestionError,
} from "./constants";
import { GET_LOCATIONS, GET_USERS } from "../../../shared/apiUrls";
import {
  saveQuestion,
  updateQuestion,
} from "../../../shared/components/Sliders/utils";
import {
  questionTypeCodes,
  questionTypeOptions,
  questionTypeOptionsShort,
} from "../../../shared/hoc/pagesWeb/incidentReporter/constants";
import { blue, fontColorLight, green, neutral } from "../../../theme/colors";
import MappableItem from "../animated/MappableItem";
import LSButton, { buttonVariants } from "../buttons/LSButton";
import LSInput, { inputVariants } from "../formInputs/LSInput";
import LSText, { textVariant } from "../text/LSText";
import "react-datepicker/dist/react-datepicker.css";
import { pageBreakPointValues } from "../../pages/constants";
import LSDropdownPicker from "../formInputs/LSDropdownPicker";
import { apiGetAuth } from "../../../shared/hoc/pagesWeb/incidentReporter/utils";
import useForms from "../../hooks/useForms";

export default function SelectNewFormQuestion({
  form,
  visible,
  editQuestion,
  setVisible,
  posX,
  posY,
  handleSetForm,
  handleEditQuestion,
  selectedQuestionAnswer,
}) {
  console.log({ selectedQuestionAnswer });
  const [question, setQuestion] = useState(
    editQuestion
      ? {
          ...selectedQuestionAnswer,
          switches:
            emptyQuestionShort[selectedQuestionAnswer.typeCode]?.switches,
        }
      : multipleChoiceEmptyQuestion
  );
  const { forms } = useForms(
    question.typeCode === questionTypeCodes.formSender
  );

  useEffect(() => {
    console.log(forms);
  }, [forms]);

  const { width } = useWindowDimensions();
  const [questionErr, setQuestionErr] = useState(
    multipleChoiceEmptyQuestionError
  );
  const [showQuestionTypeModal, setShowQuestionTypeModal] = useState(false);
  const [questionType, setQuestionType] = useState(
    editQuestion
      ? questionTypeOptionsShort[selectedQuestionAnswer.typeCode]
      : "dropdown"
  );
  const [dragItemIndex, setDragItemIndex] = useState();
  const [dragOverItemIndex, setDragOverItemIndex] = useState();

  const modalWidth = 500;
  const isMobile = useMemo(() => width < pageBreakPointValues.lg, [width]);

  const handleAddQuestionTypePress = (qType) => {
    setShowQuestionTypeModal(true);
    setQuestionType(qType);
    setQuestion(emptyQuestion[qType]);
  };

  const handleSetAnswerText = (e, name, index) => {
    const answerArr = question.answerOptions;
    answerArr[index][name] = e;

    setQuestion((prevState) => ({
      ...prevState,
      answersOptions: answerArr,
    }));
  };

  const handleSwitch = (e, name) => {
    setQuestion((prevState) => ({
      ...prevState,
      [name]: e,
    }));
  };

  const addAnswer = () => {
    setQuestion((prevState) => ({
      ...prevState,
      answerOptions: [
        ...prevState.answerOptions,
        emptyAnswer({
          id: uuid(),
          text: "",
          order: prevState.answerOptions.length,
        }),
      ],
    }));
  };

  const handleDeleteItem = (idx) => {
    const answerArr = question.answerOptions;
    answerArr.splice(idx, 1);

    answerArr.forEach((answer, i) => {
      // eslint-disable-next-line no-param-reassign
      answer.order = i;
    });

    setQuestion((prevState) => ({
      ...prevState,
      answerOptions: answerArr,
    }));
  };

  const handleDrop = () => {
    const answersArr = [...question.answerOptions];
    const dragAnswer = answersArr.splice(dragItemIndex, 1)[0];
    answersArr.splice(dragOverItemIndex, 0, dragAnswer);
    answersArr.forEach((answer, idx) => {
      // eslint-disable-next-line no-param-reassign
      answer.order = idx;
    });

    setQuestion((prevState) => ({
      ...prevState,
      answerOptions: answersArr,
    }));
  };

  const handleValidateQuestion = () => {
    let errors = 0;
    const tempQuestionErr = { ...multipleChoiceEmptyQuestionError };
    if (!question.text) {
      tempQuestionErr.text = true;
      errors += 1;
    } else {
      tempQuestionErr.text = false;
    }
    const answerOptionsArr = [];
    question.answerOptions?.forEach((answerOption) => {
      if (!answerOption.text) {
        answerOptionsArr.push(true);
      } else {
        answerOptionsArr.push(false);
      }
    });
    tempQuestionErr.answerOptions = answerOptionsArr;
    setQuestionErr(tempQuestionErr);
    return errors;
  };

  const handleSaveQuestion = async () => {
    if (!editQuestion) {
      const errors = handleValidateQuestion();
      if (!errors) {
        const order =
          selectedQuestionAnswer.subQuestions?.length ||
          selectedQuestionAnswer.order + 1 ||
          form.questions.length;
        const answerOptionUUID = selectedQuestionAnswer.typeCode
          ? selectedQuestionAnswer.answerOptionUUID
          : selectedQuestionAnswer.uuid;

        const response = await saveQuestion({
          formUUID: form.uuid,
          ...question,
          order,
          answerOptionUUID,
        });

        const subIdx = !selectedQuestionAnswer.answerOptionUUID
          ? selectedQuestionAnswer.subQuestions?.length
          : selectedQuestionAnswer.idxObject.subIdx;

        handleSetForm({
          ...response,
          idxObject: { ...selectedQuestionAnswer.idxObject, subIdx },
        });
        setVisible(false);
        setShowQuestionTypeModal(false);
        question.answerOptions.forEach((ao, idx) => {
          handleSetAnswerText("", "text", idx);
        });
      }
    } else {
      const errors = handleValidateQuestion();
      if (!errors) {
        const response = await updateQuestion(question);

        const questionsArr = [...form.questions];

        let questionIdx = -1;
        questionsArr.forEach((q, idx) => {
          if (q.uuid === response.uuid) {
            questionIdx = idx;
          }
        });

        questionsArr.splice(questionIdx, 1, response);
        handleEditQuestion({
          ...response,
          idxObject: selectedQuestionAnswer.idxObject,
        });
        setVisible(false);
        setShowQuestionTypeModal(false);
      }
    }
  };

  const handleSetDataListAnswerOptions = (
    results,
    _setQuestion,
    dataListType
  ) => {
    if (dataListType === 1) {
      _setQuestion((prevState) => ({
        ...prevState,
        answerOptions: results.map((user, idx) => ({
          id: uuid(),
          text: `${user.lastName}, ${user.firstName}`,
          order: idx,
          userUUID: user.uuid,
        })),
      }));
    } else if (dataListType === 2) {
      _setQuestion((prevState) => ({
        ...prevState,
        answerOptions: results.map((location, idx) => ({
          id: uuid(),
          text: location.name,
          order: idx,
          locationUUID: location.uuid,
        })),
      }));
    }
  };

  const handleSetDataListType = async (val) => {
    if (val === 1) {
      const results = await apiGetAuth(GET_USERS);
      handleSetDataListAnswerOptions(results, setQuestion, val);
    } else {
      const results = await apiGetAuth(GET_LOCATIONS);
      handleSetDataListAnswerOptions(results, setQuestion, val);
    }
  };

  useEffect(() => {
    setQuestion(
      editQuestion
        ? {
            ...selectedQuestionAnswer,
            switches:
              emptyQuestionShort[selectedQuestionAnswer.typeCode]?.switches,
          }
        : multipleChoiceEmptyQuestion
    );
  }, [editQuestion, selectedQuestionAnswer]);

  const renderAddQuestionBody = () => (
    <View style={styles.thinBodyContainerStyles}>
      <LSText
        variant={textVariant.h2}
        text={questionTypeOptions[questionType]}
        customStyles={{
          textAlign: "center",
          marginTop: 8,
          paddingBottom: 16,
          borderBottomColor: fontColorLight,
          borderBottomWidth: 2,
        }}
        color={fontColorLight}
      />
      <View style={styles.addQuestionModalStyles}>
        <View style={{}}>
          <LSText
            variant={textVariant.h6}
            text="Question:"
            color={fontColorLight}
          />
          <LSInput
            variant={inputVariants.underline}
            object={question}
            errorObj={questionErr}
            setter={setQuestion}
            placeholder="Question"
            name="text"
          />
          {question.typeCode === questionTypeCodes.dataSelect && (
            <View style={styles.dataSelectButtonStyles}>
              <LSDropdownPicker
                _items={dataListOptions}
                setValue={setQuestion}
                handleValueChange={handleSetDataListType}
                name="dataType"
              />
            </View>
          )}
          {question.typeCode === questionTypeCodes.formSender && (
            <View style={styles.dataSelectButtonStyles}>
              <LSDropdownPicker
                _items={forms.map((_form) => ({
                  key: _form.uuid,
                  value: _form.name,
                }))}
                setValue={setQuestion}
                handleValueChange={handleSetDataListType}
                answer={question}
                answerKey="sendFormUUID"
                name="sendFormUUID"
              />
            </View>
          )}
          {question?.answerOptions && (
            <ScrollView style={{ marginTop: "32px", height: "220px" }}>
              {question?.answerOptions?.map((answerOption, index) => (
                <MappableItem
                  key={answerOption.id}
                  setDragItemIndex={setDragItemIndex}
                  setDragOverItemIndex={setDragOverItemIndex}
                  handleDrop={handleDrop}
                  idxObject={index}
                >
                  <LSInput
                    variant={inputVariants.underline}
                    object={answerOption}
                    errorObj={{}}
                    setter={handleSetAnswerText}
                    placeholder="Answer Option"
                    name="text"
                    customStyles={{ marginVertical: 0 }}
                    selfSet={false}
                    index={index}
                    errorArr={questionErr.answerOptions}
                    rightIconElement={
                      <Pressable
                        style={{ position: "absolute", right: 16, top: 12 }}
                        onPress={() => handleDeleteItem(index)}
                      >
                        <FontAwesome
                          name="trash"
                          size={20}
                          color={fontColorLight}
                        />
                      </Pressable>
                    }
                  />
                </MappableItem>
              ))}
            </ScrollView>
          )}
          {question.typeCode === questionTypeCodes.multiple && (
            <View style={styles.addButtonContainerStyles}>
              <Pressable onPress={addAnswer}>
                <FontAwesome name="plus" size={20} color={green[400]} />
              </Pressable>
            </View>
          )}
        </View>
        <View>
          <LSText
            variant={textVariant.h4}
            text="Question Settings:"
            color={fontColorLight}
            customStyles={{ marginTop: 32, marginBottom: 8 }}
          />
          <View>
            {question?.switches?.map((_switch) => (
              <View
                key={_switch.id}
                style={{
                  marginTop: 16,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <LSText
                  variant={textVariant.h6}
                  text={
                    question[_switch.key] ? _switch.onText : _switch.offText
                  }
                  color={fontColorLight}
                />
                <Switch
                  trackColor={{ false: neutral[200], true: green[100] }}
                  thumbColor={question[_switch.key] ? green[400] : neutral[300]}
                  ios_backgroundColor={
                    question[_switch.key] ? neutral[300] : green[100]
                  }
                  onValueChange={(e) => handleSwitch(e, _switch.key)}
                  value={question[_switch.key]}
                />
              </View>
            ))}
          </View>
          <View style={styles.buttonContainerStyles}>
            <LSButton
              text="Cancel"
              variant={buttonVariants.secondary}
              customStyles={{ flex: 1 }}
              onPress={() =>
                editQuestion
                  ? setVisible(false)
                  : setShowQuestionTypeModal(false)
              }
            />
            <LSButton
              text="Save"
              variant={buttonVariants.primary}
              customStyles={{ flex: 2, marginLeft: 16 }}
              onPress={handleSaveQuestion}
            />
          </View>
        </View>
      </View>
    </View>
  );

  return (
    <>
      <Modal
        visible={visible && !editQuestion}
        transparent
        onRequestClose={() => setVisible(false)}
        style={{ position: "relative" }}
      >
        <Pressable
          style={styles.overlayStyles}
          onPress={() => setVisible(false)}
        />
        <View
          style={{
            position: "absolute",
            alignItems: "end",
            top: posY,
            left: posX - 167,
          }}
        >
          <Pressable onPress={() => setVisible(false)}>
            <AntDesign name="closecircle" size={24} color={fontColorLight} />
          </Pressable>
          {Object.values(questionTypeOptionsShort).map((qType) => (
            <View key={qType} style={styles.selectQuestionTypeContainerStyles}>
              <LSText
                variant={textVariant.caption}
                color={fontColorLight}
                customStyles={styles.selectQuestionTypeTextStyles}
                text={questionTypeOptions[qType]}
              />
              <Pressable
                style={styles.selectQuestionTypeButtonStyles}
                onPress={() => handleAddQuestionTypePress(qType)}
              />
            </View>
          ))}
        </View>
      </Modal>
      {isMobile ? (
        <Modal
          visible={showQuestionTypeModal || (visible && editQuestion)}
          transparent
          onRequestClose={() => setVisible(false)}
          animationType="slide"
          style={{ position: "relative", zIndex: 100 }}
        >
          <Pressable
            style={styles.upperStyles}
            onPress={() =>
              editQuestion ? setVisible(false) : setShowQuestionTypeModal(false)
            }
          />
          {renderAddQuestionBody()}
        </Modal>
      ) : (
        <LSModal
          visible={showQuestionTypeModal || (visible && editQuestion)}
          setVisible={setShowQuestionTypeModal}
          modalWidth={modalWidth}
        >
          <View
            style={{
              width: modalWidth,
              borderRadius: 40,
              overflow: "hidden",
            }}
          >
            {renderAddQuestionBody()}
          </View>
        </LSModal>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  upperStyles: {
    backgroundColor: "black",
    height: 100,
    opacity: 0.5,
  },
  thinBodyContainerStyles: {
    flex: 1,
    backgroundColor: blue[200],
    borderTopColor: fontColorLight,
    borderWidth: 2,
  },
  lowerStyles: {
    backgroundColor: "black",
    flex: 1,
    opacity: 0.5,
  },
  sideStyles: {
    backgroundColor: "black",
    flex: 1,
    opacity: 0.5,
  },
  bodyContainerStyles: {
    flexDirection: "row",
  },
  overlayStyles: { backgroundColor: "black", flex: 1, opacity: 0.7 },
  selectQuestionTypeContainerStyles: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 8,
  },
  selectQuestionTypeTextStyles: {
    fontWeight: "bold",
    textAlign: "end",
    width: 150,
  },
  selectQuestionTypeButtonStyles: {
    height: 16,
    width: 16,
    borderRadius: 8,
    borderWidth: 2,
    borderColor: "white",
    marginLeft: 8,
    marginRight: 4,
  },
  addQuestionModalStyles: {
    flex: 1,
    padding: 16,
    justifyContent: "space-between",
  },
  buttonContainerStyles: {
    marginTop: 32,
    flexDirection: "row",
  },
  addButtonContainerStyles: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: 16,
    marginTop: 16,
  },
  dataSelectButtonStyles: {
    marginTop: 24,
  },
});

SelectNewFormQuestion.propTypes = {
  form: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  editQuestion: PropTypes.bool,
  setVisible: PropTypes.func.isRequired,
  handleSetForm: PropTypes.func,
  handleEditQuestion: PropTypes.func,
  posX: PropTypes.number,
  posY: PropTypes.number,
  selectedQuestionAnswer: PropTypes.object,
};

SelectNewFormQuestion.defaultProps = {
  posX: 0,
  posY: 0,
  editQuestion: false,
  handleSetForm: () => {},
  handleEditQuestion: () => {},
  selectedQuestionAnswer: {},
};
